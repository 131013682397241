import CourseManageModule from './manage';
import CoursePerformModule from './perform';
import { QuizApp } from './manage/quiz_app';
import { AnalyseCustomFilter } from '../course_plan/manage/analyse_custom_filter';

export function init() {
  const manageRoot = document.getElementById('course_lessons');
  const quizRoot = document.getElementById('v-quiz-component');
  const performRoot = document.getElementById('course_perform');

  const analyseCustomGroupButton = document.querySelector('#analyse-custom-target-group');
  
  if (analyseCustomGroupButton) {
    this.analyseCustomGroup = new AnalyseCustomFilter();

    const filterElement = document.querySelector('.analyse-user-filter');

    document.querySelectorAll('.course_eval_btn').forEach(button => button.addEventListener('click', () => {
      if (button.id === 'btn-trainee_grid_wrapper') {
        filterElement.style.display = 'block';
      } else {
        filterElement.style.display = 'none';
      }
    }));
  }

  if (manageRoot) {
    this.manage = new CourseManageModule(manageRoot);
    this.quiz = new QuizApp(quizRoot);
  }

  if (performRoot) {
    this.perform = new CoursePerformModule(performRoot);
  }
}
